const phoneMask = {
    behavior: function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    options: {
        onKeyPress: function (val, e, field, options) {
            field.mask(phoneMask.behavior.apply({}, arguments), options);
        }
    }
}
const documentMask = {
    behavior: function (val) {
        return val.replace(/\D/g, '').length > 11 ? '00.000.000/0000-00' : '000.000.000-000';
    },
    options: {
        onKeyPress: function (val, e, field, options) {
            field.mask(documentMask.behavior.apply({}, arguments), options);
        }
    }
}

$(() => {
    $(".number").mask("#.##0,00", { reverse: true });
    $(".price").mask("#.##0,00", { reverse: true });
    $(".amount").mask("#.##0,00", { reverse: true });
    $(".percent").mask("##0,00", { reverse: true });
    $(".phone").mask(phoneMask.behavior, phoneMask.options);
    $(".cpf").mask("000.000.000-00");
    $(".cnpj").mask("00.000.000/0000-00");
    $(".cpf-cnpj").mask(documentMask.behavior, documentMask.options);
    $(".birthday").mask("00/00/0000");
    $(".hour").mask("00:00");
    $(".digit").mask("0#");
    $(".zipcode").mask("00000-000")
    $(".year").mask("0000")
});
